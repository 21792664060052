<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(saveForm)"
        @reset.prevent="resetForm"
      >

        <!-- razon_social -->
        <b-form-group
          label="Codigo"
          label-for="codigo"
        >
          <b-form-input
            id="codigo"
            v-model="formData.codigo"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          />
        </b-form-group>
        <!-- nombre_comercial -->
        <b-form-group
          label="Codigo Barra"
          label-for="codigoBarra"
        >
          <b-form-input
            id="codigoBarra"
            v-model="formData.codigoBarra"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <!-- direccion -->
        <b-form-group
          label="descripcion"
          label-for="Descripcion"
        >
          <b-form-input
            id="descripcion"
            v-model="formData.descripcion"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Categorias"
          label-for="categoria"
        >
          <v-select
            v-model="formData.categoriaProducto"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="descripcion"
            :options="selectCategoria"
          />
        </b-form-group>
        <b-form-group
          label="Tipo Producto"
          label-for="tipoProducto"
        >
          <v-select
            v-model="formData.tipoProductoLogistica"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="descripcion"
            :options="selectTipoProducto"
          />
        </b-form-group>
        <b-form-group
          label="Marca"
          label-for="marca"
        >
          <v-select
            v-model="formData.marcaProducto"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="descripcion"
            :options="selectMarca"
          />
        </b-form-group>
        <b-form-group
          label="Unidad Medida"
          label-for="unidadMedida"
        >
          <v-select
            v-model="formData.unidadMedida"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="descripcion"
            :options="selectUnidadMedida"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Vue from 'vue'
import { BSidebar, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const formData = ref({})
    const selectCategoria = ref([])
    const selectMarca = ref([])
    const selectTipoProducto = ref([])
    const selectUnidadMedida = ref([])
    const resetForm = () => {
      formData.value = {}
    }
    const saveForm = () => {
      let service = 'logistica/PRODUCTO_LOGISTICA_CREATE'
      if (props.formType === 'edit') {
        service = 'logistica/PRODUCTO_LOGISTICA_UPDATE'
      }
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Producto'
      }
      if (props.formType === 'edit') {
        formData.value = props.dataEdit
        titleForm.value = 'Editar Producto'
      }
    }
    const getParametros = async () => {
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store.dispatch('logistica/PRODUCTO_LOGISTICA_PARAMETROS', {
          })
            .then(response => {
              if (response) {
                selectCategoria.value = response.categorias
                selectMarca.value = response.marcas
                selectTipoProducto.value = response.tipo_producto
                selectUnidadMedida.value = response.unidad_medida
              }
            })
          // document.querySelector("#documentoCliente").focus()
          Vue.swal.close()
        },
      })
    }
    getParametros()
    return {
      formData,
      saveForm,
      resetForm,
      toggleSidebar,
      titleForm,
      getParametros,
      selectCategoria,
      selectMarca,
      selectTipoProducto,
      selectUnidadMedida,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#element-sidebar {
.vs__dropdown-menu {
max-height: 200px !important;
}
}
</style>
